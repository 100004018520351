.terms-container {
  padding-top: 120px; // Space for fixed navbar
  min-height: calc(100vh - 120px); // Full viewport height minus navbar
  background-color: #f8f9fa;

  .terms-content {
    max-width: 900px;
    margin: 0 auto;
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h1 {
      color: #2c3e50;
      border-bottom: 2px solid #e9ecef;
      padding-bottom: 1rem;
      margin-bottom: 3rem; // Increased spacing after the main title
    }

    h2 {
      color: #34495e;
      margin-top: 2.5rem; // Added spacing above section titles
    }

    p, li {
      font-size: 16px;
      line-height: 1.6;
    }

    section {
      margin-bottom: 2rem;

      &:first-of-type {
        margin-top: 0; // Remove top margin for first section
        
        h2 {
          margin-top: 0; // Remove top margin for first section title
        }
      }
    }

    ul {
      margin-left: 1.5rem;
    }
  }
}

// Mobile responsiveness
@media (max-width: 768px) {
  .terms-container {
    padding-top: 80px; // Smaller padding on mobile

    .terms-content {
      padding: 1.5rem;
      margin: 1rem;

      h1 {
        font-size: 24px;
        margin-bottom: 2.5rem; // Slightly reduced spacing on mobile but still significant
      }

      h2 {
        font-size: 20px;
        margin-top: 2rem; // Slightly reduced spacing on mobile
      }

      p, li {
        font-size: 15px;
      }
    }
  }
}
