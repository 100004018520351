@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.sports-medicine-section {
  margin-top: 150px !important;
  background: #FADBE2;
  padding: 50px;
  border-radius: 68px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px !important;
  margin-bottom: 50px;

  .sports-medicine-text {
    text-align: left;
    margin-bottom: 50px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
      max-width: 600px;
      line-height: 1.2;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  .sports-medicine-img {
    text-align: center;
    padding-top: 50px;

    img {
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .sports-facilities,
  .sports-services-section {
    margin-top: 50px;

    h3 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️';
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 20px;

          li {
            &:before {
              content: '•';
              font-size: 24px;
              top: -8px;
            }
          }
        }
      }
    }
  }

  .service-category {
    margin-bottom: 30px;

    h4 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️';
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }

        ul {
          margin-top: 10px;
          margin-bottom: 10px;
          padding-left: 20px;

          li {
            &:before {
              content: '•';
              font-size: 24px;
              top: -8px;
            }
          }
        }
      }
    }
  }

  .important-info {
    margin-top: 40px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 15px;

    h4 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #333;
    }

    p {
      font-size: 16px;
      line-height: 1.6;
      color: #555;
      margin-bottom: 0;
    }
  }

  // Responsive adjustments
  @media (max-width: 1200px) {
    .sports-medicine-text h2 {
      font-size: 40px;
    }
  }

  @media (max-width: 992px) {
    .sports-medicine-text {
      text-align: center;

      h2 {
        font-size: 40px;
        margin: 0 auto 30px;
      }

      p {
        font-size: 16px;
      }
    }

    .sports-medicine-img {
      padding-top: 20px;
    }
  }

  @media (max-width: 768px) {
    .sports-medicine-text {
      text-align: center;

      h2 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
      }
    }

    .sports-medicine-img img {
      width: 100%;
    }

    .sports-facilities ul li,
    .sports-services-section ul li,
    .service-category ul li {
      font-size: 16px;
    }

    .important-info {
      h4 {
        font-size: 22px;
      }
      p {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 30px 15px;
    margin-top: 70px !important;
    margin-bottom: 50px;
    border-radius: 20px;

    .sports-medicine-text {
      text-align: center;

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 14px;
      }
    }

    .sports-facilities ul li,
    .sports-services-section ul li,
    .service-category ul li {
      font-size: 14px;
    }

    .service-category h4 {
      font-size: 24px;
    }

    .important-info {
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
