@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.orthopedic-section {
  margin-top: 150px !important;
  background: #FADBE2;
  padding: 50px;
  border-radius: 68px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px !important;
  margin-bottom: 50px;

  .orthopedic-text {
    text-align: left;
    margin-bottom: 50px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
      max-width: 600px;
      line-height: 1.2;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  .orthopedic-img {
    text-align: center;
    padding-top: 50px;

    img {
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .facilities-section,
  .services-section,
  .hyaluronic-section {
    margin-top: 50px;

    h3 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️';
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }
      }
    }
  }

  .treatment-details {
    position: relative;

    .image-area {
      position: absolute;
      top: 50px;
      right: -850px;
      z-index: 1;

      .orthopedic-image {
        width: 650px;
        height: auto;
        border-radius: 30px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        animation: floatImage 2s ease-in-out forwards;
      }
    }
  }

  @keyframes floatImage {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  // Responsive adjustments
  @media (max-width: 1200px) {
    .orthopedic-text h2 {
      font-size: 40px;
    }
    .treatment-details .image-area {
      right: -35rem;
    }
  }

  @media (max-width: 992px) {
    .orthopedic-text {
      text-align: center;

      h2 {
        font-size: 40px;
      }
    }
    .orthopedic-img {
      padding-top: 20px;
    }
    .treatment-details .image-area {
      position: relative;
      right: auto;
      top: auto;
      margin-top: 20px;
      text-align: center;

      .orthopedic-image {
        width: 90%;
      }
    }
  }

  @media (max-width: 768px) {
    .orthopedic-text {
      text-align: center;

      h2 {
        font-size: 35px;
      }
    }

    .orthopedic-img img {
      width: 100%;
    }

    .facilities-section,
    .services-section,
    .hyaluronic-section {
      ul li {
        font-size: 16px;
      }
    }

    .treatment-details {
      .image-area {
        margin-top: 30px;
        text-align: center;

        .orthopedic-image {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding: 30px 15px;
    margin-top: 50px !important;
    margin-bottom: 50px;
    border-radius: 20px;

    .orthopedic-text {
      text-align: center;

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 16px;
      }
    }

    .facilities-section,
    .services-section,
    .hyaluronic-section {
      h3 {
        font-size: 24px;
      }

      ul li {
        font-size: 14px;
      }
    }

    .treatment-details .image-area {
      margin-top: 30px;

      .orthopedic-image {
        width: 100%;
      }
    }
  }
}
