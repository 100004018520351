@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.medical-recovery-section {
  margin-top: 150px !important;
  background: #FADBE2;
  padding: 50px;
  border-radius: 68px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px !important;
  margin-bottom: 50px;

  .medical-recovery-text {
    text-align: left;
    margin-bottom: 50px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
      max-width: 600px;
      line-height: 1.2;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  .medical-recovery-img {
    text-align: center;
    padding-top: 50px;

    img {
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .recovery-facilities,
  .recovery-services-section {
    margin-top: 50px;

    h3 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️'; // Replaces bullet points with checkmarks
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }
      }
    }
  }

  .service-category {
    margin-bottom: 30px;

    h4 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️'; // Replaces bullet points with checkmarks
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 1200px) {
    .medical-recovery-text h2 {
      font-size: 40px;
    }
  }

  @media (max-width: 992px) {
    .medical-recovery-text {
      text-align: center;

      h2 {
        font-size: 40px;
      }

      p {
        font-size: 16px;
      }
    }

    .medical-recovery-img {
      padding-top: 20px;
    }
  }

  @media (max-width: 768px) {
    .medical-recovery-text {
      text-align: center;

      h2 {
        font-size: 35px;
      }

      p {
        font-size: 16px;
      }
    }

    .medical-recovery-img img {
      width: 100%;
    }

    .recovery-facilities ul li,
    .recovery-services-section ul li {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    padding: 30px 15px;
    margin-top: 70px !important;
    margin-bottom: 50px;
    border-radius: 20px;

    .medical-recovery-text {
      text-align: center;

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 14px;
      }
    }

    .recovery-facilities ul li,
    .recovery-services-section ul li {
      font-size: 14px;
    }
  }
}
