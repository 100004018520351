@import '../../sass/colors.scss';

.programare-section {
  margin-top: 130px;
  height: 500px;

  .section-title {
    max-width: 550px;
    margin: 0 auto 40px;
    text-align: center;
  }
}

.programare-form-area {
  max-width: 750px;
  margin: -200px auto 100px;
  padding: 50px;
  background: $color-white;
  border-radius: 20px;
  box-shadow: 0px 0 20px 14px #6c757d0d;

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-row {
      display: flex;
      gap: 20px;

      .form-group {
        flex: 1;
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 8px;
          font-weight: bold;
          color: #6c757d;
        }

        input, select {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 8px;
          background: #f9f9f9;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: #42aec1;
            outline: none;
          }
        }

        select {
          appearance: none;
          background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjY2NjIiBoZWlnaHQ9IjE0IiB3aWR0aD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNSAxMUw3IDkgMTEgMTNMNyA5eiIgLz48L3N2Zz4=') no-repeat right 10px center;
          background-color: white;
          background-size: 12px;
          cursor: pointer;
        }
      }
    }

    .submit-btn {
      background: $color-blue;
      color: $color-white;
      padding: 14px 20px;
      text-decoration: none;
      border-radius: 15px;
      transition: .3s;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      margin-top: 20px;
      display: inline-block;

      &:hover {
        background: $color-white;
        color: $color-blue;
        border: 1px solid $color-blue;
      }
    }

    .form-check {
      display: flex;
      align-items: center; /* Asigură alinierea corectă între checkbox și text */
      gap: 10px; /* Spațiu între checkbox și text */
      margin-bottom: 15px; /* Distanță între checkbox-uri */

      input {
        width: 20px;
        height: 20px;
        margin: 0;
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid #ccc;

        &:focus {
          outline: 2px solid #42aec1;
        }
      }

      label {
        flex: 1; /* Asigură că textul ocupă restul spațiului */
        color: #6c757d;
        font-size: 14px;
        line-height: 1.5;
        margin: 0;
        cursor: pointer; /* Asigură că textul poate fi clicat pentru a bifa checkbox-ul */

        a {
          color: #007bff;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .contact-form {
      .form-row {
        flex-direction: column;
      }
    }
  }
}

.google-map {
  height: 600px;
  position: relative;
  margin-bottom: 30px;

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 48px;
  }

  .location-name {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    background: $color-white;
    padding: 15px;
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 30px;

    svg {
      background: $color-blue;
      color: $color-white;
      width: 60px;
      height: 50px;
      padding: 10px;
      line-height: 50px;
      border-radius: 15px;
      border: 5px solid #DBDEFA;
    }

    p {
      margin-left: 20px;
      margin-bottom: 0;
      font-weight: 600;
      color: $color-black;
    }
  }
}
