@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.main-nav {
    padding: 1px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background: $color-white;

    .navbar {
        padding: 0;

        .navbar-brand {
            img {
                max-width: 200px;    // Dimensiunea dorită a logo-ului
                height: auto;        // Asigură proporții corecte
                display: block;      // Asigură că imaginea nu primește alte stiluri inline
                margin: 0;           // Elimină orice margini implicite care ar putea afecta layout-ul
                //padding: 0;          // Elimină orice padding implicite
                padding-top: 15px;
                padding-bottom: 10px;
            }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $color-black;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 0;
                    margin: 10px 30px;

                    &:hover {
                        color: $color-blue;
                    }
                }

                &.dropdown {
                    position: relative;

                    .dropdown-menu {
                        display: none;
                        position: absolute;
                        background: $color-white;
                        border-radius: 8px;
                        box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
                        padding: 10px 0;
                        min-width: 200px;
                        
                        .dropdown-item {
                            padding: 8px 20px;
                            color: $color-black;
                            font-size: 14px;
                            font-weight: 500;
                            
                            &:hover {
                                background: $color-blue;
                                color: $color-white;
                            }
                        }
                    }

                    &:hover {
                        .dropdown-menu {
                            display: block;
                        }
                    }

                    .dropdown-toggle {
                        padding-left: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .main-nav {
        padding: 0px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;


        .navbar {
            .navbar-brand {
                img {
                    max-width: 100px;  // Ajustează dimensiunea logo-ului pentru tablete
                    height: auto;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 10px 0px;
                    }
                }
            }

            // Add spacing to the theme button on mobile
            .theme-btn {
                margin-bottom: 20px; // Adjust this value as needed
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .main-nav {
        padding: 0px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            .navbar-brand {
                img {
                    max-width: 150px;  // Ajustează dimensiunea logo-ului pentru tablete
                    height: auto;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 10px 0px;
                    }
                }
            }
        }
    }
}

// Medium Devices Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .main-nav {
        padding: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 10px 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .main-nav {
        padding: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

        .navbar {
            .navbar-brand {
                img {
                    max-width: 150px;  // Ajustează dimensiunea logo-ului pentru tablete
                    height: auto;
                }
            }
            .navbar-nav {
                .nav-item {
                    .nav-link {
                        margin: 10px 15px;
                    }
                }
            }
        }
    }
}

// Update the dropdown styles for mobile
@media (max-width: 991px) {
    .main-nav .navbar .navbar-nav .nav-item.dropdown {
        .dropdown-menu {
            display: none;
            position: static;
            box-shadow: none;
            padding-left: 20px;
            
            &.show {
                display: block;
            }
        }

        // Remove hover effect on mobile
        &:hover {
            .dropdown-menu {
                display: none;
                
                &.show {
                    display: block;
                }
            }
        }
    }
}

// Keep hover effect only for desktop
@media (min-width: 992px) {
    .main-nav .navbar .navbar-nav .nav-item.dropdown {
        &:hover {
            .dropdown-menu {
                display: block;
            }
        }
    }
}

