@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.massage-section {
  margin-top: 150px !important;
  background: #FADBE2;
  padding: 50px;
  border-radius: 68px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px !important;
  margin-bottom: 50px;

  .massage-text {
    text-align: left;
    margin-bottom: 30px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
      max-width: 600px;
      line-height: 1.2;
      color: $color-black;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
      color: $color-gray;
    }
  }

  .massage-img {
    text-align: center;
    padding-top: 20px;
    margin-bottom: 40px;

    img {
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .service-category {
    margin-bottom: 30px;

    h4 {
      font-size: 28px;
      font-weight: 600;
      color: #005670;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.6;
        color: $color-black;

        &:before {
          content: '✔️';
          position: absolute;
          left: 0;
          top: 2px;
          font-size: 18px;
        }

        p {
          margin-top: 10px;
          margin-left: -30px;
          color: $color-gray;
          font-size: 15px;
          line-height: 1.6;
        }
      }
    }
  }

  .price-list-section {
    margin-top: 50px;
    background: white;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 32px;
      font-weight: 600;
      color: #005670;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  // Responsive adjustments
  @media (max-width: 992px) {
    padding: 30px;
    
    .massage-text {
      text-align: center;
      margin-bottom: 20px;

      h2 {
        font-size: 40px;
        color: $color-black;
      }

      p {
        font-size: 16px;
        color: $color-gray;
      }
    }

    .massage-img {
      padding-top: 10px;
      margin-bottom: 30px;
    }

    .service-category {
      h4 {
        font-size: 24px;
      }

      ul li {
        font-size: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 25px;
    margin-top: 100px !important;

    .massage-text {
      margin-bottom: 15px;

      h2 {
        font-size: 32px;
        color: $color-black;
      }

      p {
        font-size: 15px;
        color: $color-gray;
      }
    }

    .massage-img {
      padding-top: 5px;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 576px) {
    padding: 20px;
    margin-top: 80px !important;
    border-radius: 30px;

    .massage-text {
      margin-bottom: 10px;

      h2 {
        font-size: 28px;
        color: $color-black;
      }

      p {
        font-size: 14px;
        color: $color-gray;
      }
    }

    .massage-img {
      padding-top: 0;
      margin-bottom: 20px;
    }

    .service-category {
      h4 {
        font-size: 22px;
      }

      ul li {
        font-size: 14px;

        p {
          font-size: 13px;
        }
      }
    }
  }
}
