@import '../../sass/colors.scss';

.single-blog {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        display: flex;
        justify-content: center;
    }

    .single-blog-area {
        background: $color-white;
        padding: 50px 40px; // Mărește padding-ul pentru mai mult spațiu în interior
        border-radius: 12px;
        max-width: 1100px; // Mărește lățimea maximă pentru a avea un aspect mai lat
        width: 100%;
        margin: auto;

        .single-blog-category {
            font-size: 12px;
            font-weight: 600;
            color: $color-blue;
            margin-bottom: 10px;
        }

        .single-blog-title {
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }

        .single-blog-banner {
            width: 100%;
            height: auto;
            margin-bottom: 30px;
            border-radius: 12px;
        }

        .single-blog-section {
            margin-bottom: 30px;

            .single-blog-section-heading {
                font-size: 20px;
                font-weight: 700;
                color: $color-black;
                margin-bottom: 15px;
            }

            .single-blog-text {
                font-size: 16px;
                line-height: 1.6;
                margin-bottom: 20px;
            }
        }

        .single-post-quote {
            background: #583fbc1f;
            padding: 15px;
            border-left: 2px solid $color-blue;
            margin-bottom: 20px;
            font-style: italic;
        }
    }
}

@media (max-width: 991px) {
    .single-blog-area {
        margin-bottom: 20px;
    }
}
