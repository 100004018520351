@import '../../sass/colors.scss';

.contact-section {
    margin-top: 130px;
    height: 500px;

    .section-title {
        max-width: 550px;
        margin: 0 auto 40px;
        text-align: center;
    }
}

.contact-form-area {
    max-width: 750px;
    margin: -200px auto 100px;
    padding: 50px;
    background: $color-white;
    border-radius: 20px;
    box-shadow: 0px 0 20px 14px #6c757d0d;
}

.google-map {
    height: 600px;
    position: relative;
    margin-bottom: 30px;

    iframe {
        width: 100%;
        height: 100%;
        border-radius: 48px; // Ajustează colțurile pentru a se potrivi cu chenarul albastru
    }

    .location-name {
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        background: $color-white;
        padding: 15px;
        width: 80%;
        margin: auto;
        display: flex;
        align-items: center;
        border-radius: 30px;

        svg {
            background: $color-blue;
            color: $color-white;
            width: 60px;
            height: 50px;
            padding: 10px;
            line-height: 50px;
            border-radius: 15px;
            border: 5px solid #DBDEFA;
        }

        p {
            margin-left: 20px;
            margin-bottom: 0;
            font-weight: 600;
            color: $color-black;
        }
    }
}