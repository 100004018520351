@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.service-box {
    border: 1px solid #1819451a;
    padding: 15px;
    border-radius: 32px;
    text-align: center;
    margin-bottom: 30px;

    .icon-area {
        background: $color-blue;
        padding: 80px;
        border-radius: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-box {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
        }
    }

    .service-text {
        h3 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;

            a {
                text-decoration: none;
                color: #181945;
            }
        }

        p {
            font-size: 14px;
        }
    }
}

// Small Device Responsive
@media (min-width: 576px) and (max-width: 767px) {
    .service-box {
        .icon-area {
            padding: 50px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .service-box {
        .icon-area {
            padding: 60px;
        }
    }
}

// Medium Device Responsive
@media (min-width: 992px) and (max-width: 1199px) {
    .service-box {
        .icon-area {
            padding: 50px;
        }
    }
}