@import '../../sass/colors.scss';

.service-section {
    .service-title-text {
        padding-top: 50px;
        max-width: 600px;
        margin: 0 0 0 auto;
        font-weight: 500;
    }

    .services-slider {
        margin: 30px -15px;
        
        .slick-slide {
            padding: 0 15px;
            
            & > div {
                height: 100%;
            }
            
            .slider-item {
                height: 100%;
                
                .service-box {
                    height: 100%;
                    margin: 0;
                }
            }
        }

        .slick-list {
            margin: 0 -15px;
            padding: 15px 0;
        }

        .slick-dots {
            bottom: -40px;
            
            li {
                button:before {
                    font-size: 12px;
                    color: $color-blue;
                }
                
                &.slick-active button:before {
                    color: $color-blue;
                }
            }
        }

        .slick-prev, .slick-next {
            width: 40px;
            height: 40px;
            z-index: 1;
            
            &:before {
                font-size: 40px;
                color: $color-blue;
            }
        }

        .slick-prev {
            left: -50px;
        }

        .slick-next {
            right: -50px;
        }
    }

    .services-link {
        margin: 80px 0 30px;

        a {
            color: $color-blue;
            text-decoration: none;
            font-weight: 700;
            background: #583fbc1e;
            padding: 15px 25px;
            display: inline-block;
            border-radius: 15px;

            svg {
                margin-left: 10px;
                font-size: 20px;
                top: -2px;
                position: relative;
            }
        }
    }
}

//Mobile Responsive
@media (max-width: 575px){
    .service-section {
        .service-title-text {
            padding-top: 0;
            margin-bottom: 10px;
        }
        
        .services-slider {
            margin: 15px auto 40px;
            max-width: 85%;
            
            .slick-slide {
                padding: 0 10px;
            }
            
            .slick-list {
                padding: 10px 0;
            }
            
            .slick-prev, .slick-next {
                display: none !important;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .service-section {
        .service-title-text {
            padding-top: 0;
            margin-bottom: 30px;
        }
        
        .services-slider {
            margin: 20px auto 40px;
            max-width: 90%;
            
            .slick-prev, .slick-next {
                display: none !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .service-section {
        .services-slider {
            .slick-prev, .slick-next {
                display: none !important;
            }
        }
    }
}