@import '../../sass/colors.scss';
@import '../../sass/fonts.scss';

.icon-box {
    background: $color-white;
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    text-align: center;

    &::before {
        position: absolute;
        content: '';
        width: 100px;
        height: 100px;
        background: $color-white;
        top: -10px;
        left: -10px;
        z-index: -1;
        opacity: .3;
        border-radius: 20px;
    }

    img {
        max-width: 50px;
    }
}


// Mobile Responsive
@media (max-width: 575px) {
    .icon-box {
        width: 80px;          // Increased from 60px to 80px
        height: 80px;         // Increased from 60px to 80px
        line-height: 80px;    // Matches the new height for vertical centering
        border-radius: 16px;  // Increased to maintain proportion

        &::before {
            width: 100px;        // Increased from 80px to 100px
            height: 100px;       // Increased from 80px to 100px
            border-radius: 20px; // Increased to maintain proportion
        }

        img {
            max-width: 60px;    // Increased from 40px to 60px
        }
    }
}



// Responsive Styles for Small Devices
@media (min-width: 576px) and (max-width: 767px) {
    .icon-box {
        width: 60px;          // Increased from 40px to 60px
        height: 60px;         // Increased from 40px to 60px
        line-height: 60px;    // Matches the new height
        border-radius: 12px;  // Increased to maintain proportion

        &::before {
            width: 80px;         // Increased from 60px to 80px
            height: 80px;        // Increased from 60px to 80px
            border-radius: 16px; // Increased to maintain proportion
        }

        img {
            max-width: 40px;    // Increased from 25px to 40px
        }
    }
}
