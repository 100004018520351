@import '../sass/colors.scss';

.section-bg.section-common.thank-you-header {
    background: linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%);
    padding: 120px 0 60px;
    
    .section-title {
        margin-bottom: 0;
        text-align: center;
        
        h2 {
            color: #2b373b;
            margin-bottom: 15px;
        }
        
        p {
            color: #666;
            font-size: 18px;
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

.thank-you-content {
    padding: 80px 0;
    background-color: #ffffff;

    .container {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 15px;
    }

    .content-wrapper {
        background: #fff;
        border-radius: 15px;
        padding: 50px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        text-align: center;
        max-width: 800px;
        margin: 0 auto;

        .icon-wrapper {
            width: 100px;
            height: 100px;
            background: lighten($color-blue, 25%);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 30px;

            svg {
                color: $color-blue;
                font-size: 50px;
            }
        }

        h2 {
            color: #2b373b;
            font-size: 32px;
            margin-bottom: 30px;
            font-weight: 600;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 0 40px;
            text-align: left;

            li {
                position: relative;
                padding-left: 30px;
                margin-bottom: 20px;
                color: #666;
                font-size: 18px;
                line-height: 1.6;

                &:before {
                    content: "✓";
                    position: absolute;
                    left: 0;
                    color: $color-blue;
                    font-weight: bold;
                }
            }
        }

        .buttons {
            margin-top: 40px;

            .btn-primary {
                background: $color-blue;
                color: white;
                padding: 15px 30px;
                border-radius: 50px;
                text-decoration: none;
                font-weight: 500;
                transition: all 0.3s ease;
                display: inline-block;
                border: 2px solid $color-blue;

                &:hover {
                    background: transparent;
                    color: $color-blue;
                }
            }
        }
    }
}

// Responsive design
@media (max-width: 768px) {
    .section-bg.section-common.thank-you-header {
        padding: 100px 0 40px;
        
        .section-title {
            h2 {
                font-size: 32px;
            }
            
            p {
                font-size: 16px;
                padding: 0 20px;
            }
        }
    }

    .thank-you-content {
        padding: 40px 0;

        .content-wrapper {
            padding: 30px 20px;

            h2 {
                font-size: 24px;
            }

            ul li {
                font-size: 16px;
            }

            .buttons .btn-primary {
                padding: 12px 25px;
            }
        }
    }
}
