@import '../../sass/colors.scss';

.single-testimoni {
    width: 100%;
    padding: 50px 10px 0;
    margin-bottom: 30px;
}

.testimonial-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background: $color-white;
    border-radius: 32px;
    padding: 40px 20px 20px;
    position: relative;
    min-height: 320px;
    box-sizing: border-box;

    .testimonial-img {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        margin: auto;

        img {
            border: 8px solid #D9EBFA;
            border-radius: 50%;
            display: inline-block;
        }
    }

    .testimonial-text {
        margin-top: 50px;
        flex-grow: 1;

        h3 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        p {
            font-size: 15px;
            margin-bottom: 0px;
        }
    }

    .testimonial-rating {
        margin-top: 5px;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            color: #FF8C4B;
            font-size: 25px;
            margin: 0 2px;
        }
    }
}

/* Mobile custom scaling (screens narrower than 576px) */
@media (max-width: 575px) {
    .testimonial-card {
        padding: 20px 15px 15px; /* Reduce padding for mobile */
        min-height: 320px; /* Set a standard minimum height */
        height: 100%; /* Ensure the height is the same for all cards */
    }

    .testimonial-img {
        top: -30px; /* Adjust top positioning for smaller screens */

        img {
            border: 5px solid #D9EBFA; /* Adjust border size for mobile */
            width: 60px; /* Scale down the image */
            height: 60px;
        }
    }

    .testimonial-text {
        margin-top: 40px; /* Adjust margin between image and text */
        h3 {
            font-size: 18px; /* Reduce font size for mobile */
            margin-bottom: 8px; /* Adjust spacing */
        }

        p {
            font-size: 13px; /* Slightly smaller font size for mobile */
            flex-grow: 1; /* Allow text to fill space in between */
        }
    }

    .testimonial-rating {
        margin-top: 5px;
        li {
            font-size: 22px; /* Slightly smaller stars for mobile */
        }
    }
}
