@import '../../sass/colors.scss';

.team-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    .section-title {
        h2 {
            max-width: inherit;
        }
    }

    .department-section {
        width: 100%;
        margin-bottom: 60px;

        .department-title {
            text-align: center;
            margin-bottom: 30px;
            padding-top: 20px;
            font-size: 28px;
            font-weight: 700;
        }

        .row {
            display: flex;
            justify-content: center;
            gap: 40px;
            padding-bottom: 20px;
        }
    }

    .team-card {
        text-align: center;
        margin-bottom: 30px;

        .team-img {
            margin-bottom: 20px;
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: #DBEFFA;
                content: '';
                z-index: -1;
                border-radius: 10px;
            }
        }

        h3 {
            font-size: 18px;
            font-weight: 600;
        }

        .team-info {
            font-size: 16px;
            line-height: 1.5;
            color: $color-grey-dark;
        }
    }
}

// Small Device Responsive
@media (max-width: 575px) {
    .team-section {
        .pt-5 {
            padding-top: 0 !important;
        }
    }

    .department-section {
        .row {
            flex-direction: column;
            gap: 20px;
        }
    }
}