@import './../../sass/fonts.scss';
@import './../../sass/colors.scss';

.dermatology-section {
  margin-top: 150px !important;
  background: #FADBE2;
  padding: 50px;
  border-radius: 68px;
  position: relative;
  padding-bottom: 100px;
  padding-top: 100px !important;
  margin-bottom: 50px;

  .dermatology-text {
    text-align: left;
    margin-bottom: 50px;

    h2 {
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 30px;
      max-width: 600px;
      line-height: 1.2;
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  .dermatology-img {
    text-align: center;
    padding-top: 50px;

    img {
      border-radius: 20px;
      max-width: 100%;
      height: auto;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }
  }

  .dermatology-services-section,
  .dermapen-section {
    margin-top: 50px;

    h3 {
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;

        &:before {
          content: '✔️';
          position: absolute;
          left: 0;
          top: 0;
          font-size: 18px;
        }
      }
    }

    p {
      font-size: 18px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
  }

  // Responsive adjustments
  @media (max-width: 992px) {
    .dermatology-text {
      text-align: center;

      h2 {
        font-size: 40px; // Reduced font size for mobile
      }

      p {
        font-size: 16px; // Adjusted paragraph font size
      }
    }

    .dermatology-img {
      padding-top: 20px;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 15px; // Adjusted padding for mobile view
    margin-top: 70px;
    margin-bottom: 70px;

    .dermatology-text {
      text-align: center;

      h2 {
        font-size: 35px; // Reduced font size to prevent breaking out
        margin-bottom: 20px;
      }

      p {
        font-size: 16px;
      }
    }

    .dermatology-img img {
      width: 100%; // Ensure image is responsive
    }

    .dermatology-services-section ul li,
    .dermapen-section ul li {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    padding: 20px 10px;
    margin-top: 70px !important;
    margin-bottom: 50px;
    border-radius: 20px;

    .dermatology-text {
      h2 {
        font-size: 30px; // Further reduced font size for very small screens
      }

      p {
        font-size: 14px;
      }
    }

    .dermatology-services-section ul li,
    .dermapen-section ul li {
      font-size: 14px;
    }
  }
}
