.service-banner-section {
    margin-top: 130px;

    .service-banner-text {
        overflow: hidden;
        padding: min(50px, 4vw) min(30px, 3vw);
        h2 {
            font-size: min(50px, 3.5vw);
            font-weight: 700;
            margin-bottom: 30px;
            max-width: 600px;
            line-height: 1.2;
            word-wrap: break-word;
        }

        p {
            margin-bottom: 50px;
            word-wrap: break-word;
            font-size: min(18px, 1.2vw);
            line-height: 1.6;
        }

        .theme-btn {
            white-space: normal;
            a {
                font-size: 14px;
            }
        }
    }

    .service-banner-img {
        text-align: center;
        padding-top: 80px;
        margin-bottom: -100px;

        img {
            border-radius: 20px;
            max-width: 100%;
            height: auto;
        }
    }
}

// Responsive adjustments
@media (max-width: 1200px) {
    .service-banner-section {
        .service-banner-text {
            h2 {
                font-size: 40px;
            }
        }
    }
}

@media (max-width: 992px) {
    .service-banner-section {
        .service-banner-text {
            text-align: center;

            h2 {
                font-size: 40px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 768px) {
    .service-banner-section {
        .service-banner-text {
            text-align: center;

            h2 {
                font-size: 35px;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 575px) {
    .service-banner-section {
        margin-top: 70px;
        padding: 30px 0 50px;

        .service-banner-text {
            text-align: center;

            h2 {
                font-size: 30px;
            }

            p {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }

        .service-banner-img {
            text-align: center;
            padding-top: 20px;
            margin-bottom: 0;

            img {
                border-radius: 20px;
            }
        }
    }
}