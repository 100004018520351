@import './sass/fonts.scss';
@import './sass/colors.scss';

// Common CSS
body {
    padding: 0;
    margin: 0;
    font-family: $body-font;
}
img {
    max-width: 100%;
}
p {
    color: $color-gray;
    font-size: 18px;
}
h1, h2, h3, h4, h5, h6 {
    color: $color-black;
    margin-bottom: 0;
}
.theme-btn {
    a {
        background: $color-blue;
        color: $color-white;
        padding: 14px 20px;
        text-decoration: none;
        border-radius: 15px;
        transition: .3s;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);

        &:hover {
            background: $color-white;
            color: $color-blue;
            border: 1px solid $color-blue;
        }
    }
}
.section-bg {
    background-color: #DBEFFA;
}
.section-common {
    border-radius: 68px;
    margin: 0 100px;
}

.d-table {
    margin-top: 8%;
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}

.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}

// Mobile Responsive
@media (max-width: 575px){
    .section-common {
        margin: 0 15px;
        border-radius: 20px;
    }

    p {
        font-size: 16px;
    }

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .section-common {
        margin: 0 15px;
        border-radius: 20px;
    }
    .d-table {
        margin-top: 20%;
    }

    p {
        font-size: 16px;
    }

    .pt-100 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
}

// Medium Responsive
@media (min-width: 758px) and (max-width: 991px) {
    .pt-100 {
        padding-top: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .d-table {
        margin-top: 30%;
    }
}
